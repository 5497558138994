import React from "react"
import CTAInLink from "./CTAInLink"

export default function () {
  return (
    <div className="px-4 md:px-8 py-16 -my-8 md:text-base text-center">
      <h2 className="section-head text-2xl md:text-4xl lg:text-5xl ">
        Be a <span className="text-red-800">Bloom </span>
        Nepal Student
      </h2>
      <div className="mt-12 -ml-8 flex-row flex-wrap justify-center">
        <div className="inline-block ml-8">
          <CTAInLink
            text="Admission"
            linkto="/admission"
            className="bg-blue-800 hover:bg-blue-900"
          />
        </div>
        <div className="mt-4 ml-8 inline-block">
          <CTAInLink
            text="Scholarship"
            linkto="/scholarship"
            className="bg-red-800 hover:bg-red-900"
          />
        </div>
      </div>
    </div>
  )
}
