import React from "react"
import { useState } from "react"
import Layout from "../components/layout"
import BeABloomie from "../components/beBloomie"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import ValidateEmail, { ValidatePhone, handleFormChange } from "../utils/form"
import { todayAndNextYearDate, allSchoolLocations } from "../utils/helpers"
import Form from "../components/form"
import ChuckJoke from "../components/chuckjoke"
import SEO from "../components/seo"

const locations = allSchoolLocations()

const VisitForm2 = ({ locations }) => {
  const [formFields, setFormFields] = useState({
    name: "",
    phone: "",
    email: "",
    date: "",
    campusToVisit: "select",
  })

  const [minVisitDate, maxVisitDate] = todayAndNextYearDate()
  const dataValidations = {
    name: function (nameInput) {
      return nameInput.length > 2
    },
    phone: ValidatePhone,
    email: ValidateEmail,
    date: function (inputDate) {
      return (
        new Date(inputDate) >= new Date(minVisitDate) &&
        new Date(inputDate) <= new Date(maxVisitDate)
      )
    },
    campusToVisit: function (location) {
      return location.toLowerCase() !== "select"
    },
  }

  // Function that describes the logic of handling submissions
  function handleSubmit() {
    // Submit Data to Netlify
    const ResponseMessage = () => {
      if (formFields.name === "7147suman") {
        return <div>Welcome Home, Suman</div>
      } else if (formFields.name === "tellmeajoke") {
        return <ChuckJoke />
      } else {
        return (
          <div className="mt-4">
            <p className="text-xl">
              Thanks for your response. We will contact you soon to finalize
              your visit timesoon to finalize your visit times.
            </p>
            <p className="text-2xl mt-2">
              We will see you soon{" "}
              <span role="img" aria-label="celebration">
                🎉
              </span>
            </p>
          </div>
        )
      }
    }
    let actionTo = null
    return {
      responseToForm: <ResponseMessage />,
      actionTo: actionTo,
    }
  }

  // Function to handle Changes
  function handleChange(e) {
    handleFormChange(e, setFormFields, formFields)

    // If the target field has met validation rule,
    // remove invalidation texts
    if (dataValidations[e.target.name](e.target.value)) {
      document.querySelector(`.${e.target.name}.error`).style.display = "none"
    }
  }

  //   Styling Elements

  //   Component that is to be rendered
  return (
    <div className="px-4 md:px-8 lg:pr-32 xl:pr-64">
      <h3 className="section-head">Request School Visit</h3>
      <p className="mt-4">
        Please fill out the form below to let us know which school location and
        when do you intend to visit us. Of course, you can visit us anytime at
        your convinience but this will help us plan things in advance
      </p>
      <Form
        onSubmit={handleSubmit}
        dataValidations={dataValidations}
        submitToNetlify={true}
        formValues={{ ...formFields, "form-name": "Visit Request Form" }}
        formName="Visit Request Form"
      >
        <input type="hidden" name="form-name" value="Visit Request Form" />
        <div className="grid grid-cols-1">
          <div className="form-field">
            <label htmlFor="name" className="block">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={formFields.name}
              onChange={handleChange}
              name="name"
              placeholder="Eg: Ramesh Poudel"
            />
            <div className="error text-sm name text-red-700 hidden">
              Please enter proper name
            </div>
          </div>
          <div className="form-field">
            <label htmlFor="phone" className="block">
              Phone Number
            </label>
            <input
              type="phone"
              value={formFields.phone}
              onChange={handleChange}
              name="phone"
              id="phone"
              placeholder="9812345678"
            />
            <div className="error text-sm phone  text-red-700 hidden">
              Invalid or missing phone number
            </div>
          </div>
          <div className="form-field">
            <label htmlFor="email" className="block">
              Email
            </label>
            <input
              type="email"
              value={formFields.email}
              onChange={handleChange}
              name="email"
              id="email"
              placeholder="xyz@bloomn.edu.np"
            />
            <div className="error text-sm email  text-red-700 hidden">
              Sorry, we couldn't validate the email
            </div>
          </div>
          <div className="form-field">
            <label htmlFor="date" className="block">
              Intended Date of Visit
            </label>
            <input
              type="date"
              value={formFields.date}
              onChange={handleChange}
              name="date"
              id="date"
              min={minVisitDate}
              max={maxVisitDate}
            />
            <div className="error text-sm date text-red-700 hidden">
              Sorry, we only accept visit requests for future dates no later
              than 1 year from today.
            </div>
          </div>
          <div className="form-field">
            <label htmlFor="campusToVist" className="block">
              Which location would you like to visit?
            </label>
            <select
              name="campusToVisit"
              id="campusToVisit"
              required
              value={formFields.campusToVisit}
              onChange={handleChange}
            >
              <option>Select</option>
              {locations.map(location => (
                <option key={location}>Bloom Nepal School, {location}</option>
              ))}
            </select>
            <div className="error text-sm campusToVisit  text-red-700 hidden">
              Please select the school you want to visit.
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

const AllSchoolLocations = ({ images: imageData }) => {
  const LOCATION_NAMES = {
    lalitpur: "lalitpur",
    itahari: "itahari",
  }
  const schools = [
    {
      locationName: LOCATION_NAMES.lalitpur,
      location: "Mahalaxmi - 8, Sankhadevi, Lalitpur",
      phone: "+9779851147140, +977 1-5709030",
      email: "info@bloomn.edu.np",
      whatsapp : "+9779851147140",
      direction:
        "The nearest landmark is Sankhadevi temple, which is 5 minutes of walk from the school. We are about 2kms from School Chowk, the nearest busstop in Sanagaun.",
      mapsUrl: "https://goo.gl/maps/3w5idpXGTM7RFZMx9",
    },
    {
      locationName: LOCATION_NAMES.itahari,
      location: "Balgram Chowk, Itahari ",
      phone: "+977 25-585512",
      email: "info@bloomn.edu.np",
      direction:
        "Our school is located about 0.6 kms away from Balgram Chowk, Itahari.",
      mapsUrl: "https://goo.gl/maps/nA6Np5YbXGFfREFh8",
    },
  ]

  const [selectedSchool, setSelectedSchool] = useState(0)
  const currentSchoolContact = schools[selectedSchool]

  return (
    <div>
      <div className="md:text-center">
        <h2 className="section-head" id="locations">
          Locations
        </h2>
        <div className="my-2 flex flex-wrap md:justify-center md:mt-4">
          {schools.map((school, index) => (
            <button
              onClick={() => {
                setSelectedSchool(index)
              }}
              key={index}
              className={` button-outline location-button
            ${
              selectedSchool === index ? "bg-red-800 shadow-2xl" : "bg-gray-600"
            }`}
            >
              Bloom Nepal School,{" "}
              <span className="capitalize font-medium">
                {schools[index].locationName}
              </span>
            </button>
          ))}
        </div>
      </div>
      <div className="lg:flex lg:mt-16 mt-12 lg:items-center">
        <div className="lg:w-1/2 lg:pr-8 ">
          <div className="mt-4">
            <h4 className="mt-4 font-semibold uppercase tracking-wider text-sm text-red-800">
              Details
            </h4>
            <div className="">
              <div>
                {currentSchoolContact.location}{" "}
                <a
                  href={`${currentSchoolContact.mapsUrl}`}
                  className="external-url"
                >
                  <span className="text-sm"> (Google Maps)</span>
                </a>{" "}
              </div>
              <div> Phone : {" "} {currentSchoolContact.phone}</div>
              <div> WhatsAPP/Viber : {" "} {currentSchoolContact.whatsapp}</div>
              <div>
              Email : {" "}
                <a
                  href={`mailto: ${currentSchoolContact.email}`}
                  className="external-url"
                >
                  {currentSchoolContact.email}
                </a>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <h4 className="font-semibold uppercase tracking-wider text-sm text-red-800">
              Directions
            </h4>
            <div>
              <p>{currentSchoolContact.direction}</p>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 lg:pl-8">
          {currentSchoolContact.locationName === LOCATION_NAMES.lalitpur ? (
            <div className="">
              <Img
                className="mt-4"
                fluid={imageData.lalitpurImg.childImageSharp.fluid}
                alt="Bloom Nepal School Lalitpur"
                draggable={false}
              />
              <p className="caption"> Rainy Day Bloom Nepal School </p>
            </div>
          ) : (
            <div className="">
              <Img
                className="mt-4"
                fluid={imageData.itahariImg.childImageSharp.fluid}
                alt="Bloom Nepal School Itahari"
                draggable={false}
              />
              <p className="caption"></p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const data = graphql`
  query {
    lalitpurImg: file(relativePath: { eq: "bloom-lalitpur-in-rain.jpeg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid
        }
      }
    }
    itahariImg: file(relativePath: { eq: "bloom-lalitpur-location.jpeg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid
        }
      }
    }
    ContactRoad: file(relativePath: { eq: "students-jumping.jpeg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function ({ data }) {
  const imageData = data
  return (
    <Layout>
      <SEO title="Contact"/>
      {/* <section>
        <Calendar />
      </section> */}
      <section className="px-4 w-full lg:border-r lg:border-b border-gray-600 lg:text-center md:pb-20 lg:px-20 md:py-16">
        <div className="section-for-small-devices">
          <h1 className="section-head md:text-center">
            {" "}
            Contact <br className="md:hidden" />
            <span className="text-red-800 text-2xl ">with good old email</span>
          </h1>
          <div className="lg:px-16">
            <p className="mt-4 md:mt-12">
              Probably the best way for you to reach out to us is to send us an
              email at{" "}
              <a href="mailto:info@bloomn.edu.np" className="in-link">
                info@bloomn.edu.np
              </a>{" "}
              and we will connect you to the concerned person/department.
            </p>
            <p className="mt-2 md:mt-4">
              We are a relatively small organization and won't mind a bit of
              email forwarding.
            </p>
          </div>
        </div>
      </section>
      <section className="md:mt-32 md:py-8 md:px-12 lg:border-l lg:border-b md:border-gray-600 lg:text-left">
        <div className="section-for-small-devices lg:flex lg:items-center">
          <div className="mt-8 md:mt-0 lg:w-1/2 lg:pr-8">
            <h2
              id="meetInPerson"
              className="section-head text-2xl md:text-4xl text-blue-800 md:text-gray-800"
            >
              Meet us <span className="">in Person</span>
            </h2>
            <p className="mt-2">
              We would love to show you the campus and around!
            </p>
            <p className="mt-2 md:my-4 lg:mt-32 border-b-2 border-red-800 inline-block leading-none">
              Request a site visit below &darr;
            </p>
          </div>
          <div className="lg:w-1/2 lg:pl-8">
            <div className="">
              <Img
                className="mt-4"
                fluid={imageData.ContactRoad.childImageSharp.fluid}
                alt="Close up of two female students landing from a jump"
                draggable={false}
              />
            </div>
          </div>
        </div>
      </section>
      <section className=" lg:border-b lg:border-r md:border-gray-600">
        <div className="section-for-small-devices">
          <AllSchoolLocations images={imageData} />
        </div>
      </section>
      <section
        id="visit"
        className="lg:border-l lg:border-b md:border-gray-600"
      >
        <div className="section-for-small-devices">
          <div className="mt-8 md:mt-8">
            <VisitForm2 locations={locations} />
          </div>
        </div>
      </section>
      <section className="">
        <BeABloomie />
      </section>
    </Layout>
  )
}