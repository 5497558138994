export default function ValidateEmail(mail) {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(mail)) {
    return true
  }
  return false
}

export function ValidatePhone(phone) {
  const re = /^((00|\+)(\d{1,3}(\s|-|){0,1}\s*|)|)\d{9,11}$/
  return phone.match(re) !== null
}

export function handleFormChange(e, setFormFields, formFields) {
  //   Get the name of the input field that triggered the handleChange
  const inputFieldName = e.target.name

  // Get the value the use has entered
  const userEnteredValue = e.target.value

  // Set the value of the relevant input field
  let b = { ...formFields }
  b[inputFieldName] = userEnteredValue
  setFormFields(b)
}
