import React, { useState, useEffect } from "react"

function ChuckJoke() {
  const [joke, setJoke] = useState("")
  function newJoke() {
    setJoke("loading")
    fetch("https://api.chucknorris.io/jokes/random")
      .then(response => response.json())
      .then(data => setJoke(data.value))
  }
  useEffect(() => {
    //   perform the fetch from chuck norris api
    newJoke()
  }, [])
  return (
    <div className="rainbox-text-animation mt-4 text-2xl">
      Joke?? Try this:
      <p className="falling-animation animation-3 mt-4 text-xl">{joke}</p>
      <button
        className="block text-white mt-2 px-2 py-1 bg-black button-outline"
        onClick={newJoke}
      >
        Get New Joke
      </button>
    </div>
  )
}

export default ChuckJoke
