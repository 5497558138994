import React from "react"
import { Link } from "gatsby"

const CTAInlink = props => {
  return (
    <div className="mt-8 mb-4">
      <Link
        to={`${props.linkto}`}
        className={`px-4 py-3 md:px-10 text-white bg-gray-800 md:py-4 uppercase button-outline ${props.className}`}
      >
        {props.text}
      </Link>
    </div>
  )
}

export default CTAInlink
