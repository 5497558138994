import React, { useState, useRef, useEffect } from "react"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Form({
  children,
  onSubmit,
  dataValidations,
  submitToNetlify,
  formValues,
  formName,
}) {
  const [submitHit, setSubmitHit] = useState(false)
  const [
    submissionProcessingStarted,
    setSubmissionProcessingStarted,
  ] = useState(false)
  const [submissionProcessingDone, setSubmissionProcessingDone] = useState(
    false
  )
  const [systemError, setSystemError] = useState(null)
  const formRef = useRef(null)
  const [successMessage, setSuccessMessage] = useState(
    <div>Default Success Message</div>
  )

  // Disable input, select fields of the the
  // form if the form is valid and has been successfuly submitted
  // because users don't have requireemnt to submit the form anymore
  // but they might want to see the information they submitted
  // This function is called after the systemError is updated
  function decideToDisableInputs() {
    if (!systemError) {
      formRef.current
        .querySelectorAll("input, select , option")
        .forEach(item => (item.disabled = true))
    } else {
      formRef.current
        .querySelectorAll("input, select, option")
        .forEach(item => (item.disabled = false))
    }
  }

  useEffect(() => {
    if (submitHit && submissionProcessingStarted) {
      // alert('gone in')
      decideToDisableInputs()
    }
  }, [
    systemError,
    submissionProcessingDone,
    submitHit,
    submissionProcessingStarted,
  ])

  // Handles Submission
  function handleSubmit(e) {
    e.preventDefault()
    // only submit if the form hasn't already been submitted
    // Which means there is either system error or processing isn't complete
    if (systemError || !submissionProcessingDone) {
      // Default cases in case of resubmission
      setSubmitHit(false)
      setSubmissionProcessingStarted(false)
      setSubmissionProcessingDone(false)
      setSystemError(false)

      // set the submit hit to true
      setSubmitHit(true)

      // Check if the field entries are valid
      let dataIsValidSoFar = true
      let dataValidationIndex = 0
      let firstErrorName, foundFirstError
      while (dataValidationIndex < Object.keys(dataValidations).length) {
        const currentItemKey = Object.keys(dataValidations)[dataValidationIndex]
        const currentItemValidationRule = dataValidations[currentItemKey]

        // Get the value of the current item
        const currentItemValue = formRef.current.querySelector(
          `[name = ${currentItemKey}]`
        ).value

        // Increment the dataValidation Index
        dataValidationIndex++

        // Set the value of dataIsValidSoFar to false
        // if the value doesn't match the validation rule
        if (!currentItemValidationRule(currentItemValue)) {
          dataIsValidSoFar = false

          // Show Error Message
          formRef.current.querySelector(
            `.${currentItemKey}.error`
          ).style.display = "block"

          // Focus that element
          // We need to foucs on the first error element so let's catch taht
          if (!foundFirstError) {
            firstErrorName = currentItemKey
            foundFirstError = true
          }
        }
      }

      // Focus on the element with first error if exists
      if (foundFirstError) {
        formRef.current.querySelector(`[name = ${firstErrorName}]`).focus()
      }

      // If invalid data, display error message and return
      // Otherwise
      // Do Data Processing

      if (!dataIsValidSoFar) {
        // setSubmitHit(false)
        // setSubmissionProcessingStarted(false)
        return
      }

      // Set the remote processing started to be true
      setSubmissionProcessingStarted(true)

      // If some error happend in data processing, show error and return
      // Otherwise
      // Show the sucess message

      // If data is to be submitted to netlify then do
      // Process submission to netlify

      // console.log("got here")
      if (submitToNetlify) {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode(formValues),
        })
          .then(() => {
            // console.log("in then ")
          })
          .catch(error => {
            setSystemError(true)
            setSubmissionProcessingDone(true)
          })
      }

      const { responseToForm, actionTo } = onSubmit()
      // if and when the processing is successful
      try {
        if (actionTo) {
          // console.log("going to action?")
          // Open the action link in new tab
          window.open(
            actionTo,
            "_blank" // <- This is what makes it open in a new window.
          )
        }
        // console.log("done onsubmti of the othe fuctnion")
        setSystemError(false)
        setSubmissionProcessingDone(true)

        // set the success message
        setSuccessMessage(responseToForm)
      } catch {
        setSystemError(true)
        setSubmissionProcessingDone(true)
      }
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      ref={formRef}
      method={submitToNetlify ? "POST" : ""}
      data-netlify={submitToNetlify ? "true" : ""}
      name={formName}
      className="mt-4 sm:mt-6 lg:mt-8"
      action=""
    >
      {children}
      {/* {/* // If the form hasn't yet been submitted, dispaly the submit button */}
      {!submitHit ||
      (submitHit && !submissionProcessingStarted) ||
      systemError ? (
        <div>
          {systemError ? (
            <div className="my-4 text-lg">
              Sorry, there was a problem submitting your response. Would you
              mind giving it another try?
            </div>
          ) : (
            ""
          )}
          <input
            type="submit"
            className="font-main cursor-pointer bg-blue-800 hover:bg-blue-900 text-white"
            value={systemError ? "Sure, let's retry" : "Submit Details"}
            onClick={handleSubmit}
          />
        </div>
      ) : // If the form has beeb submitted and the processing is ongoing
      submitHit && submissionProcessingStarted && !submissionProcessingDone ? (
        <div className="bg-red-700 px-2 py-1 text-white cursor-wait">
          <svg
            className="animate-spin h-5 w-5 mr-3 inline-block"
            viewBox="0 0 24 24"
          >
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Processing
        </div>
      ) : (
        // Submission is done, and processing is finished
        <div>
          <button
            type="button"
            className="bg-green-600 text-white px-2 py-1 inline-block cursor-not-allowed"
            disabled
          >
            Successfully Submitted
          </button>
          <p className="text-sm mt-4">
            Your information is successfully submitted and the the form above
            has been made readonly for the purposes of your personal record.
          </p>
          {successMessage}
        </div>
      )}
    </form>
  )
}
